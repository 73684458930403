import { useState } from "react";
import { Stack, DefaultButton, Icon, Persona, PersonaSize } from "@fluentui/react";
import styles from "./OptionsUser.module.css";
import Login from "../Modals/Login/Login";
import Buy from "../Modals/Buy/Buy";
import { useAuth } from "../../context/Auth";
import History from "../Modals/History/History";
import Notification from "../Modals/Notification/Notification";

export const OptionsUser = () => {
    const [modal, setModal] = useState(false);
    const [comprar, setComprar] = useState(false);
    const [dropMenu, setDropMenu] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const { user, logout } = useAuth();
    const handleModal = () => {
        setModal(!modal);
    };

    const handleLogin = (opt: boolean) => {
        setModal(opt);
    };

    const handleModalBuy = () => {
        setComprar(!comprar);
    };

    const handleLogout = () => {
        setDropMenu(false);
        logout(); // Cerrar sesión
    };

    const handleMenu = () => {
        setDropMenu(!dropMenu);
    }

    const handleHistory = () => {
        setShowHistory(!showHistory);
    }

    const handleBuy = () => {
        if(user){
            setComprar(true);
        }else{
            setModal(true);
        }
    }

    return (
        <>
            <Stack horizontal className={styles.questionInputContainer}>
                {user ? (<>
                    <DefaultButton onClick={handleBuy} text="Comprar" />
                    <div className={styles.userMenu}>
                        <span onClick={handleMenu}><Icon iconName="Contact" /> {  user?.user?.name }</span>
                        {
                            dropMenu && 
                        <ul className={styles.userDropdown}>
                            <li onClick={handleHistory}>Historial</li>
                            <li onClick={handleLogout}>Salir</li>
                        </ul>
                        }
                    </div>
                    </>
                ) : (
                    <DefaultButton text="Iniciar sesión" onClick={handleModal} />
                )}
            </Stack>
            {(modal || !user) && <Login showModal={handleLogin} />}
            {comprar && <Buy showModal={handleModalBuy}/>}
            {showHistory && <History showModal={handleHistory}/>}
            <Notification/>
        </>
    );
};

import React, { useState } from "react";
import Styles from "./Buy.module.css";
import mainIcon from "../../../assets/modal/logo.png";
import FormBuyer from "../../forms/FormBuyer/FormBuyer";
interface Props {
  showModal: () => void;
}


const Buy = ({ showModal } : Props) => {
  const [formType, setFormType] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [directionDevice, setDirectionDevice] = useState("31312");


  return (
    <div className={Styles.modal}>
      <div className={Styles.modalContent}>
        <span className={Styles.closeButton} onClick={showModal}>
          x
        </span>
        <div className={Styles.imgSection}>
          <img src={mainIcon} alt="red juridica" />
        </div>
        <div className={Styles.main}>
          <h3 className={Styles.titleForm}>
            Compra preguntas
          </h3>
          {/* <div className={Styles.iconsSocial}>
            <img className={Styles.IconGoogle} src={IconGoogle.src} />
            <img className={Styles.IconFb} src={IconFb.src} />
          </div> */}
          <FormBuyer showModal={() => showModal()} selectDirection={setDirectionDevice} selectDevice={setIsPhone} isPhone={isPhone}/>
          {/* <div className={Styles.LineFooter}>
            <p className={Styles.descLink}>
              Descripcion
            </p>
            <input
              className={Styles.registerLink}
              type="checkbox"
              onClick={() =>
                setFormType(
                  !formType
                )
              }
              id="register-link"
      
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Buy;

import React, { useEffect, useState } from "react";
import Styles from "./Notification.module.css";
import mainIcon from "../../../assets/modal/logo.png";
import { useLocation, useNavigate, useParams } from 'react-router-dom';



const Notification = () => {
  const queryParams = new URLSearchParams(window.location.search);
  // Obtener los parámetros de la query string
  const state = queryParams.get("transactionState");
  const reference = queryParams.get("referenceCode");
  const merchantId = queryParams.get("merchantId");
  const TX_VALUE = queryParams.get("TX_VALUE");
  const currency = queryParams.get('currency');
  const email = queryParams.get('buyerEmail');
  const date = queryParams.get('processingDate');
  const transaction = queryParams.get('transactionId');
  const navigate = useNavigate();

  const estadoTransaccion = (state: string | null) => {
    switch (state) {
      case "4":
        return "Aprobado";
      case "6":
        return "Rechazado";
      case "7":
        return "Pendiente";
      case "104":
        return "Error";
      case "5":
        return "Expirada";
      default:
        return "";
    }
  }

  const toHome = () => {
    console.log('test')
    navigate('/')
  }

  const generarPDF = () => {
    window.print();
  }

  return (
    reference ?
      <div className={Styles.modal}>
        <div className={Styles.modalContent}>
          <div className={Styles.imgSection}>
            <img src={mainIcon} alt="red juridica" />
          </div>
          <div className={Styles.main}>
            <div className={Styles.details}>
              <h2>Detalles de la transacción</h2>
              <p><span>Estado:</span> {estadoTransaccion(state)}</p>
              <p><span>Referencia:</span> {reference}</p>
              <p><span>Transacción:</span> {transaction}</p>
              <p><span>Comprador:</span> {email}</p>
              <p><span>Fecha:</span> {date}</p>
              <p><span>Valor:</span> {TX_VALUE}</p>
              <p><span>Moneda:</span> {currency}</p>
            </div>
            {/* <div className={Styles.buttonsConfirm}>
              <a href="/" rel="home">Entendido</a>
            </div> */}
            <div className={Styles.buttonsConfirm}>
              <a href="/" rel="home">Entendido</a>
              <button onClick={generarPDF}>Generar PDF</button>
            </div>
            {/* <h3 className={Styles.titleForm}>
          Histórico
        </h3> */}
          </div>
        </div>
      </div> : <></>

  );
};

export default Notification;

import React, { useEffect, useState } from "react";
import Styles from "./History.module.css";
import mainIcon from "../../../assets/modal/logo.png";
import { toast } from "react-toastify";
import { Payment, historyPayments } from "../../../api/user";
import TablePayments from "../../Tables/TableTokens/TablePayments";
import { useAuth } from "../../../context/Auth";


interface Props {
  showModal: () => void;
}


const History = ({ showModal } : Props) => {
  const [data, setData] = useState<Payment[] | null>(null);
  const { user } = useAuth();

  const fetchData = async() => {
    try{
      const response = await historyPayments(user ? user?.token : '');
      console.log(response);
      toast.success('Consulta existosa')
      setData(response)
    }catch(error: any){
      console.log(error)
      toast.error('Ha ocurrido un error')
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className={Styles.modal}>
      <div className={Styles.modalContent}>
        <span className={Styles.closeButton} onClick={showModal}>
          x
        </span>
        <div className={Styles.imgSection}>
          <img src={mainIcon} alt="red juridica" />
        </div>
        <div className={Styles.main}>
          <h3 className={Styles.titleForm}>
            Histórico
          </h3>
          <TablePayments data={data}/>
        </div>
      </div>
    </div>
  );
};

export default History;

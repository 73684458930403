import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./FormBuyer.module.css";
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { toast } from 'react-toastify';
import { Spinner } from "@fluentui/react";
import { useAuth } from "../../../context/Auth";
import dep_ciudades from "../../../departamentos-ciudades-siigo.json";

interface Props {
  showModal: () => void;
  selectDirection: (form: string) => void;
  selectDevice: (isPhone: boolean) => void;
  isPhone: boolean;
}

interface DataItem {
  departamento: string;
  ciudad: string;
  pais: string;
  cod_departamento: string;
  cod_ciudad: string;
}

const FormBuyer: React.FC<Props> = ({ showModal, selectDevice, selectDirection, isPhone }) => {
  const [loading, setLoading] = useState(false);
  const [referenceCode, setReferenceCode] = useState('');
  const [signature, setSignature] = useState('');
  const [quantity, setQuantity] = useState<number | ''>('');
  const [cost, setCost] = useState('');
  const [costPreview, setCostPreview] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [tel, setTel] = useState("");
  const [errorTel, setErrorTel] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [addForm, setAddform] = useState(false);
  const [errorTerms, setErrorTerms] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [city, setCity] = useState("Bogota");
  const [address, setAddress] = useState("");
  const [errorType, setErrorType] = useState("");
  const [documentUser, setDocumentUser] = useState("");
  const [errorDocument, setErrorDocument] = useState("");
  const [dataSiigo, setDataSiigo] = useState<DataItem[]>(dep_ciudades);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const { user } = useAuth();
  const step: number = 50;
  const ucost: number = 1500;

  const validateFirstName = () => {
    if (!firstName) {
      setErrorFirstName("Campo obligatorio*");
      return false;
    }
    return true;
  };

  const validateLastName = () => {
    if (!lastName) {
      setErrorLastName("Campo obligatorio*");
      return false;
    }
    return true;
  };

  const validateTel = () => {
    if (!tel) {
      setErrorTel("Campo obligatorio*");
      return false;
    }
    return true;
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setErrorEmail("Campo obligatorio*");
      return false;
    } else if (!emailRegex.test(email)) {
      setErrorEmail("El formato no es válido");
      return false;
    }
    return true;
  };

  const validateTypeDocument = () => {
    if (!typeDocument) {
      setErrorType("Campo obligatorio*");
      return false;
    }
    return true;
  };

  const validateDocumentUser = () => {
    if (!documentUser) {
      setErrorDocument("Campo obligatorio*");
      return false;
    }
    
    if (!/^\d+$/.test(documentUser)) {
      setErrorDocument("El documento debe ser numérico*");
      return false;
    }
  
    if (typeDocument === "NIT" && documentUser.length > 9) {
      setErrorDocument("El documento NIT debe tener máximo 9 dígitos*");
      return false;
    }
  
    if (typeDocument === "CC" && documentUser.length > 10) {
      setErrorDocument("El documento CC debe tener máximo 10 dígitos*");
      return false;
    }
  
    // Si todas las validaciones pasan
    setErrorDocument(""); // Limpiar cualquier error previo
    return true;
  };
  

  const validateForm = () => {
    let valid = true;
    if (addForm) {
      valid = validateFirstName() && valid;
      valid = validateLastName() && valid;
      valid = validateTel() && valid;
      valid = validateEmail() && valid;
      valid = validateTypeDocument() && valid;
      valid = validateDocumentUser() && valid;
    }
    return valid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      toast.error('Por favor corrige los errores en el formulario.');
      return;
    }
    try {
      setLoading(true);
      let dataJson = {}
      if (addForm) {
        dataJson = {
          quantity: quantity,
          user: {
            nombre_fac: firstName,
            apellido_fac: lastName,
            celular_fac: tel,
            correo_fac: email,
            tipo_documento_fac: typeDocument,
            numero_documento_fac: documentUser,
            departamento_fac: selectedDepartment,
            ciudad_fac: selectedCity,
            direccion_fac: address
          }
        }
      } else {
        dataJson = {
          quantity: quantity
        }
      }
      const referenceResponse = await fetch('/transaction', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": user && user.token ? user.token : ''
        },
        credentials: 'include',
        body: JSON.stringify(dataJson)
      });
      if (!referenceResponse.ok) {
        throw new Error('Error al obtener la referencia');
      }
      const data = await referenceResponse.json();
      setReferenceCode(data.reference);
      setSignature(data.signature);
      setCost(data.cost);
      setLoading(false);
      toast.success('Transacción creada exitosamente.');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Ha ocurrido un error inesperado. Por favor intente de nuevo más tarde.');
    }
  };

  const handleFirstName = (event: any) => {
    setFirstName(event.target.value);
    setErrorFirstName("");
  };

  const handleLastName = (event: any) => {
    setLastName(event.target.value);
    setErrorLastName("");
  };

  const handleEmail = (event: any) => {
    setEmail(event.target.value);
    setErrorEmail("");
  };

  const handleTel = (event: any) => {
    setTel(event.target.value);
    setErrorTel("");
  };

  const handleType = (event: any) => {
    setTypeDocument(event.target.value);
    setErrorType("");
  };

  const handleDocument = (event: any) => {
    setDocumentUser(event.target.value);
    setErrorDocument("");
  };

  const handleCity = (event: any) => {
    setCity(event.target.value);
  };

  const handleAddress = (event: any) => {
    setAddress(event.target.value);
  };

  useEffect(() => {
    const payuForm = document.getElementById('payuForm') as HTMLFormElement;
    if (payuForm && referenceCode && signature) {
      payuForm.submit();
    }
  }, [referenceCode, signature]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value);
    setQuantity(Number.isNaN(inputValue) ? '' : inputValue);
    setCostPreview(inputValue * ucost);
  };

  const handleFormStyle = (event: any) => {
    setAddform(event.target.checked);
  };

  const uniqueDepartments: { key: string, value: string }[] = Array.from(new Set(dataSiigo.map(item => item.cod_departamento)))
    .map(departmentCode => {
      const department = dataSiigo.find(d => d.cod_departamento === departmentCode)!;
      return {
        key: department.cod_departamento,
        value: department.departamento,
      };
    });

  const handleDepartmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDepartment(event.target.value);
    setSelectedCity(''); // Reset city when department changes
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCity(event.target.value);
  };

  const filteredCities: { key: string, value: string }[] = selectedDepartment
    ? dataSiigo.filter(item => item.cod_departamento === selectedDepartment)
      .map(city => ({
        key: city.cod_ciudad,
        value: city.ciudad,
      }))
    : [];

  return (
    <div className={styles.formularioContainer}>
      <form method="post" id="payuForm" action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/" onSubmit={handleSubmit}>
        <input name="merchantId" type="hidden" value="508029" />
        <input name="accountId" type="hidden" value="512321" />
        <input name="description" type="hidden" value="Compra de preguntas" />
        <input name="referenceCode" type="hidden" value={referenceCode} />
        <input name="amount" type="hidden" value={cost} />
        <input name="tax" type="hidden" value="0" />
        <input name="taxReturnBase" type="hidden" value="0" />
        <input name="currency" type="hidden" value="COP" />
        <input name="signature" type="hidden" value={signature} />
        <input name="test" type="hidden" value="0" />
        <input name="buyerEmail" type="hidden" value={addForm ? email : user?.user.email} />
        <input name="responseUrl" type="hidden" value="https://jerre.normograma.com/" />
        <input name="confirmationUrl" type="hidden" value="https://jerre.normograma.com/confirmation" />
        <input name="algorithmSignature" type="hidden" value="SHA256" />
      </form>
      <form onSubmit={handleSubmit}>
        <div className={styles.cost}>
          Costo: <span>${costPreview.toLocaleString('es')}</span>
        </div>
        {addForm && <div className={styles.addDetails}>
          <div className={styles.row}>
            <div className={`${styles.ancho50}`}>
              <div className={`${styles.campo} ${errorFirstName ? styles.error : ""}`}>
                <div className={styles.titulo}>Nombre / Razón social</div>
                <input
                  type="text"
                  onChange={handleFirstName}
                  value={firstName}
                />
              </div>
              <div className={styles.errorText}>{errorFirstName && errorFirstName}</div>
            </div>

            <div className={`${styles.ancho50}`}>
              <div className={`${styles.campo} ${errorLastName ? styles.error : ""}`}>
                <div className={styles.titulo}>Apellido</div>
                <input
                  type="text"
                  onChange={handleLastName}
                  value={lastName}
                />
              </div>
              <div className={styles.errorText}>{errorLastName && errorLastName}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.anchoTotal}`}>
              <div className={`${styles.campo} ${errorTel ? styles.error : ""}`}>
                <div className={styles.titulo}>Celular</div>
                <input
                  onChange={handleTel}
                  value={tel}
                  type="text"
                />
              </div>
              <div className={styles.errorText}>{errorTel && errorTel}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.anchoTotal}`}>
              <div className={`${styles.campo} ${errorEmail ? styles.error : ""}`}>
                <div className={styles.titulo}>Correo electrónico</div>
                <input
                  onChange={handleEmail}
                  value={email}
                  type="email"
                />
              </div>
              <div className={styles.errorText}>{errorEmail && errorEmail}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.ancho50}`}>
              <div className={`${styles.campo} ${errorType ? styles.error : ""}`}>
                <div className={styles.titulo}>Tipo de documento</div>
                <select onChange={handleType} value={typeDocument}>
                  <option value={""}>Tipo de documento</option>
                  <option value={"CC"}>Cédula de ciudadania</option>
                  <option value={"NIT"}>NIT</option>
                </select>
              </div>
              <div className={styles.errorText}>{errorType && errorType}</div>
            </div>
            <div className={`${styles.ancho50}`}>
              <div className={`${styles.campo} ${errorDocument ? styles.error : ""}`}>
                <div className={styles.titulo}>N. documento</div>
                <input
                  onChange={handleDocument}
                  type="text"
                  value={documentUser}
                />
              </div>
              <div className={styles.errorText}>{errorDocument && errorDocument}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.ancho50}`}>
              <div className={`${styles.campo} ${errorType ? styles.error : ""}`}>
                <div className={styles.titulo}>Departamento</div>
                <select value={selectedDepartment} onChange={handleDepartmentChange}>
                  <option value="">Departamento</option>
                  {uniqueDepartments.map(department => (
                    <option key={department.key} value={department.key}>{department.value}</option>
                  ))}
                </select>
              </div>
              <div className={styles.errorText}>{errorType && errorType}</div>
            </div>
            {selectedDepartment && (
              <div className={`${styles.ancho50}`}>
                <div className={`${styles.campo} ${errorType ? styles.error : ""}`}>
                  <div className={styles.titulo}>Ciudad</div>
                  <select value={selectedCity} onChange={handleCityChange}>
                    <option value="">Ciudad</option>
                    {filteredCities.map(city => (
                      <option key={city.key} value={city.key}>{city.value}</option>
                    ))}
                  </select>
                </div>
                <div className={styles.errorText}>{errorDocument && errorDocument}</div>
              </div>
            )}
          </div>
          {selectedDepartment && (
            <div className={styles.row}>
              <div className={`${styles.anchoTotal}`}>
                <div className={`${styles.campo} ${errorEmail ? styles.error : ""}`}>
                  <div className={styles.titulo}>Dirección</div>
                  <input
                    onChange={handleAddress}
                    type="text"
                    value={address}
                  />
                </div>
                <div className={styles.errorText}>{errorEmail && errorEmail}</div>
              </div>
            </div>
          )}
        </div>}
        <input type="number" className={styles.campo} value={quantity === '' ? '' : quantity.toString()}
          placeholder="cantidad"
          onChange={handleChange}
          step={step.toString()}
          min="0"
        />
        <div className={styles.row}>
          <div className={`${styles.anchoTotal}`}>
            <div className={`${styles.invoiceCheck}`}>
              <input type="checkbox" id="invoiceDetails" onChange={handleFormStyle} />
              <label htmlFor="invoiceDetails">¿Desea cambiar los datos de su factura electrónica?</label>
            </div>
            <div className={styles.errorText}>{errorTerms && errorTerms}</div>
          </div>
        </div>
        <div className={styles.buttonsConfirm}>
          <button name="Submit" disabled={(quantity === '')} className={`${(quantity !== '') ? styles.validButton : ""} ${styles.submitButton}`} type="submit" value="Enviar">
            {loading && <Spinner />}Comprar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormBuyer;
